<template>
    <div class='grid'>
        <div class='col-12 md:col-6'>
            <Tag class='mb-2 cursor-pointer' icon='pi pi-plus' @click='prepareAddModule' severity='info'>Aggiungi
                modulo
            </Tag>
            <BlockUI :blocked='editModule.id !== 0'>
                <Accordion v-model:activeIndex='active'>
                    <AccordionTab v-for='(module, index) in modules' :key='module.id'>
                        <template #header>
                            <div class='flex justify-content-between flex-wrap' style='width: 100%'>
                                <div class='flex align-items-center justify-content-center'>
                                    {{ module.rank }}
                                    {{ module.slug }}
                                </div>
                                <div class='flex align-items-center justify-content-center'>
                                    <Tag v-if='editModule.id === 0' class='mr-2' icon='pi pi-pencil' severity='info'
                                         value='Modifica' @click.stop='startEditing(module, index)'></Tag>
                                    <Tag v-if='module.publish_state === "published"' class='mr-2' icon='pi pi-check'
                                         severity='success' value='Published'></Tag>
                                    <Tag v-if='module.publish_state === "ready"' class='mr-2'
                                         icon='pi pi-exclamation-triangle' severity='info' value='Ready'></Tag>
                                    <Tag v-if='module.publish_state === "draft"' class='mr-2' icon='pi pi-info-circle'
                                         severity='danger' value='Draft'></Tag>
                                    <Tag v-if='module.id === editModule.id' class='mr-2'
                                         icon='pi pi-exclamation-triangle' severity='warning' value='Editing'></Tag>
                                </div>
                            </div>
                        </template>
                        <Module :model='module' :bookSlugs='row.books || {}' :episodeSlugs='row.episodes || {}' />
                    </AccordionTab>
                </Accordion>
            </BlockUI>
        </div>
        <div class='col-12 md:col-6 text-center'>
            <Edit v-if='editModule.id !== 0' :module='editModule' @reset='setEditModule({id: 0})' />
        </div>
    </div>
</template>

<script>

import Module from './Module';
import Edit from './Edit';
import Navigations from '../../../../mixins/navigations';
import Notifications from '../../../../mixins/notifications';
import { createNamespacedHelpers } from 'vuex';

const { mapActions, mapMutations, mapGetters } = createNamespacedHelpers('Cont/B2BPaths');

//import model from './model.js';
export default {
    components: { Module, Edit },
    data() {
        return {
            active: 0,
            maxRank: 0,
        };
    },
    mounted() {
        this.initModel();
    },
    watch: {
        modules() {
            this.initModel();
        },
        lang() {
            this.startEditing({ id: 0 });
        }
    },
    computed: {
        ...mapGetters(['editModule', 'row']),
    },
    methods: {
        ...mapActions(['addModule']),
        ...mapMutations(['setEditModule']),
        startEditing(mod, idx = null) {
            if (idx !== null) {
                this.active = idx;
            }
            this.setEditModule(mod);
        },
        initModel() {
            this.maxRank = 0;
            if (this.modules && this.modules.length) {
                this.modules.forEach(mod => {
                    console.log("MOD", mod);
                    if (mod.rank > this.maxRank) {
                        this.maxRank = mod.rank;
                    }
                });
            }
        },
        prepareAddModule() {
            //TODO uno per volta
            const mod = {
                id: 0,
                rank: this.maxRank + 1,
                path_id: this.path_id,
                locale: this.lang,
                publish_state: 'draft',
            };
            mod.slug = this.bootstrapSlug(mod);
            this.saveNewModule(mod);
        },
        bootstrapSlug(mod) {
            return this.translation.slug + '-module-' + mod.rank;
        },
        async saveNewModule(mod) {
            this.addModule(mod)
                .then(() => {
                    this.ShowSuccess('Ottimo', 'Modulo salvato con successo');
                }).catch(this.ShowApiError);
        },
    },
    mixins: [
        Navigations, Notifications,
    ],
    props: {
        lang: {
            type: String,
            default: 'it',
            required: true,
        },
        path_id: {
            type: Number,
            required: true,
        },
        translation: {
            type: Object,
        },
        modules: {
            type: Array,
            default() {
                return [];
            },
        },

    },
};
</script>

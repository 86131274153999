<template>
    <Card>
        <template #title>
            <h5 v-if='id === 0'>Nuovo record</h5>
            <h5 v-else>{{ pageTitle }} {{ languageString }}
                <Flag v-if='lang' :code="lang" :name="language" :width='24' :class="{'mr-1': true}"/>
            </h5>
        </template>
        <template #content v-if='row'>
            <TabMenu v-if='id !== 0' :model="tabMenus" :active-index='activeIndex'/>
            <PlainForm v-if='lang === ""'/>
            <TranslatedForm v-else :lang='lang' :id='row.id'/>
        </template>
    </Card>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapActions, mapGetters, mapMutations } = createNamespacedHelpers('Cont/B2BPaths');
const commonStore = createNamespacedHelpers('Common');

import Factory from './model';
import Notifications from '../../../mixins/notifications';
import Navigations from '../../../mixins/navigations';
import PageGuards from '../../../mixins/guards';

import PlainForm from './Plain';
import TranslatedForm from './Translated';
import dd_companies from '../../../mixins/dd_companies';

export default {
    beforeRouteLeave(to, from, next){
        this.beforeRouteLeaveCheck(to, from, next);
    },
    beforeRouteUpdate(to, from, next){
        this.beforeRouteUpdateCheck(to, from, next);
    },
    mounted(){
        this.loadData(this.company_id, this.id);
    },
    computed: {
        ...mapGetters(['row', 'loading', 'dirtyForm']),
        ...commonStore.mapGetters(['languages']),
        companyName(){
            if (this.company_id > 0) {
                const company = this.getCompany(this.company_id);
                if (!company) return "";
                return company.name;
            }
            return "";
        }
    },
    methods: {
        ...mapActions(['fetchOne']),
        ...mapMutations(['setRow', 'formIsClean']),
        ...commonStore.mapMutations(['setExtraBreadcrumbs']),
        setPageBreadcrumbs(){
            if (this.company_id > 0 && this.id > 0) {
                this.setExtraBreadcrumbs([
                    { label: this.companyName, to: '/app/users/companies/'+this.company_id},
                    { label: "Percorsi", to: '/app/users/companies/'+this.company_id+'/paths'},
                    { label: 'Modifica' }
                ]);
                return;
            }
            if (this.company_id > 0) {
                this.setExtraBreadcrumbs([
                    { label: this.companyName, to: '/app/users/companies/'+this.company_id},
                    { label: "Percorsi", to: '/app/users/companies/'+this.company_id+'/paths'},
                    { label: 'Nuovo' }
                ]);
            }
        },
        tabsWithTranslations: (basePath, company_id, id, languages) => {
            let n = 0;
            const menu = [
                {
                    label: 'Base',
                    icon: 'pi pi-fw pi-globe',
                    idx: n,
                    to: basePath + '/' + company_id + '/paths/' + id,
                },
            ];
            Object.keys(languages).forEach(k => {
                menu.push({
                    label: languages[k]['label'],
                    idx: ++n,
                    code: k,
                    to: basePath + '/' + company_id + '/paths/' + id + '/' + k,
                });
            });
            return menu;
        },
        loadData(company_id, id){
            const bp = this.$route['meta']['basePath'] || '';
            this.tabMenus = this.tabsWithTranslations(bp, company_id, id, this.languages);
            if (id === 0){
                const mod = Factory.newModel(this.languages);
                mod.company_id = company_id;
                this.setRow(mod);
            } else {
                this.setPageBreadcrumbs();
                this.fetchOne(id).then(res => {
                    this.setPageTitle(Factory.title(res));
                }).catch(err => {
                    this.ShowStandardError(err.response);
                }).finally(() => {});
            }

        }
    },
    props: {
        id: {
            default: 0,
            type: Number
        },
        lang: {
            default: "",
            type: String
        },
        company_id: {
            default: 0,
            type: Number
        }
    },
    watch: {
        id(n, o){
            if (n !== o) {
                this.loadData(this.company_id, n);
            }
        },
        lang(n, o){
            if (n !== o) {
                this.setPageBreadcrumbs();
            }
        },
        dd_mapped_companies(){
            this.setPageBreadcrumbs();
        }
    },
    mixins: [
        Notifications,
        Navigations,
        PageGuards,
        dd_companies
    ],
    components: {
        PlainForm,
        TranslatedForm
    }
}
</script>

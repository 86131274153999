<template>
    <Button label="Annulla"
            class="p-button-raised p-button-danger mr-2 mb-2"
            :disabled='load'
            :icon="icon"/>
</template>
<script>

export default {
    computed: {
        icon(){
            return this.load ? "pi pi-spin pi-spinner" : "pi pi-times";
        }
    },
    props: {
        load: {
            type: Boolean,
            default(){
                return false;
            }
        },
    }
}
</script>

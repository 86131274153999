<template>
    <div>
        <ConfirmDialog></ConfirmDialog>
        <h5>{{ model.title }}</h5>
        <div v-html='model.description'>
        </div>
        <div class='grid p-fluid formgrid mt-3'>
            <div class='field col-12 text-left'>
                <h6>Libri</h6>
                <DataTable
                    :value='books'
                    sortField='order'
                    :sortOrder='1'
                    class='mt-3 p-shadow-1 p-datatable-sm'>
                    <template #empty>
                        Non ci sono libri correlati.
                    </template>
                    <Column header='Ordine' :style="{width:'44px'}">
                        <template #body='{data}'>
                            <InputNumber v-model='data.rank' :style="{width:'40px'}"
                                         @update:modelValue='updateBookRank(data)' />
                        </template>
                    </Column>
                    <Column header='Titolo' :style="{minWidth:'40%'}">
                        <template #body='{data}'>
                            <span>{{ getBookTitle(data) }}</span>
                        </template>
                    </Column>
                    <Column header='Type' :style="{minWidth:'3em'}">
                        <template #body='{data}'>
                            <Tag v-if='data.rank > 0' class='mr-2' icon='pi pi-exclamation-mark' severity='info'
                                 value='featured'></Tag>
                        </template>
                    </Column>
                    <Column header='' :style="{width:'10%'}">
                        <template #body='{data}'>
                            <Tag class="cursor-pointer" title='Elimina' icon="pi pi-trash ml-1" severity="danger" @click='removeBook(data)'></Tag>
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>

        <div class='grid p-fluid formgrid mt-3'>
            <div class='field col-12 text-left'>
                <h6>Episodi</h6>
                <DataTable
                    :value='episodes'
                    sortField='order'
                    :sortOrder='1'
                    class='mt-3 p-shadow-1 p-datatable-sm'>
                    <template #empty>
                        Non ci sono libri correlati.
                    </template>
                    <Column header='Ordine' :style="{width:'44px'}">
                        <template #body='{data}'>
                            <InputNumber v-model='data.rank' :style="{width:'40px'}"
                                         @update:modelValue='updateEpisodeRank(data)' />
                        </template>
                    </Column>
                    <Column header='Titolo' :style="{minWidth:'40%'}">
                        <template #body='{data}'>
                            <span>{{ getEpisodeTitle(data) }}</span>
                        </template>
                    </Column>
                    <Column header='Type' :style="{minWidth:'3em'}">
                        <template #body='{data}'>
                            <Tag v-if='data.rank > 0' class='mr-2' icon='pi pi-exclamation-mark' severity='info'
                                 value='featured'></Tag>
                        </template>
                    </Column>
                    <Column header='' :style="{width:'10%'}">
                        <template #body='{data}'>
                            <Tag class="cursor-pointer" title='Elimina' icon="pi pi-trash ml-1" severity="danger" @click='removeEpisode(data)'></Tag>
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapActions } = createNamespacedHelpers('Cont/B2BPaths');
import Notifications from '../../../../mixins/notifications';

export default {
    mounted() {
        this.resetBooksRankMap(this.model);
    },
    methods: {
        ...mapActions(['patchModuleBooks', 'deleteModuleBook', 'deleteModuleEpisode', 'patchModuleEpisodes']),
        getBookTitle(row) {
            if (this.bookSlugs && this.bookSlugs[row.book_id]) {
                return this.bookSlugs[row.book_id];
            }
            return row.book_id;
        },
        getEpisodeTitle(row) {
            if (this.episodeSlugs && this.episodeSlugs[row.episode_id]) {
                return this.episodeSlugs[row.episode_id];
            }
            return row.episode_id;
        },
        resetBooksRankMap(n){
            this.booksRankMap = {};
            if (n.book_ids){
                n.book_ids.forEach(book => {
                    this.booksRankMap[book['book_id']] = book['rank'] || 0;
                })
            }
            this.resetEpisodesRankMap(n);
        },
        resetEpisodesRankMap(n){
            this.episodesRankMap = {};
            if (n.episode_ids){
                n.episode_ids.forEach(epi => {
                    this.episodesRankMap[epi['book_id']] = epi['rank'] || 0;
                })
            }
        },
        removeBook(mod){
            this.$confirm.require({
                message: 'Sei sicuro di voler eliminare il libro dal modulo?',
                header: 'Conferma l\'eliminazione',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.doRemoveBook(mod);
                    this.$confirm.close();
                },
                reject: () => {
                    this.$confirm.close();
                },
            });

        },
        async doRemoveBook(mod){
            try {
                await this.deleteModuleBook(mod);
            } catch (e) {
                this.ShowApiError(e);
            }
        },
        removeEpisode(mod){
            this.$confirm.require({
                message: 'Sei sicuro di voler eliminare questo episodio dal modulo?',
                header: 'Conferma l\'eliminazione',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.doRemoveEpisode(mod);
                    this.$confirm.close();
                },
                reject: () => {
                    this.$confirm.close();
                },
            });

        },
        async doRemoveEpisode(mod){
            try {
                await this.deleteModuleEpisode(mod);
            } catch (e) {
                this.ShowApiError(e);
            }
        },
        async updateBookRank(mod) {
            if (this.booksRankMap[mod['book_id']] === mod['rank']) return;
            try {
                if (mod.rank !== 0){
                    for (const book of this.books) {
                        if (book.book_id !== mod.book_id && book.rank >= mod.rank) {
                            book.rank++;
                            await this.patchModuleBooks({bookModule: book, doMutation: false});
                        }
                    }
                }
                await this.patchModuleBooks({bookModule: mod, doMutation: true});
            } catch (e) {
                this.ShowApiError(e);
            }


            this.ShowSuccess('Ottimo', 'Libri aggiornati con successo');
        },
        async updateEpisodeRank(mod) {
            if (this.episodesRankMap[mod['episode_id']] === mod['rank']) return;
            try {
                if (mod.rank !== 0){
                    for (const episode of this.episodes) {
                        if (episode.episode_id !== mod.episode_id && episode.rank >= mod.rank) {
                            episode.rank++;
                            await this.patchModuleEpisodes({bookModule: episode, doMutation: false});
                        }
                    }
                }
                await this.patchModuleEpisodes({bookModule: mod, doMutation: true});
            } catch (e) {
                this.ShowApiError(e);
            }


            this.ShowSuccess('Ottimo', 'Episodi aggiornati con successo');
        },
    },
    mixins: [Notifications],
    data() {
        return {
            booksRankMap: {},
            episodesRankMap: {}
        };
    },
    computed: {
        books(){
            if (this.model.book_ids){
                return this.model.book_ids.map(book => {
                    if (!book.rank) book.rank = 0;
                    book.order = book.rank;
                    if (book.order === 0) book.order = 1000000;
                    return book;
                });
            }
            return [];
        },
        episodes(){
            if (this.model.episode_ids){
                return this.model.episode_ids.map(episode => {
                    if (!episode.rank) episode.rank = 0;
                    episode.order = episode.rank;
                    if (episode.order === 0) episode.order = 1000000;
                    return episode;
                });
            }
            return [];
        }
    },
    watch: {
        model(n){
            this.resetBooksRankMap(n);
        }
    },
    props: {
        model: {
            type: Object,
            required: true,
        },
        bookSlugs: {
            type: Object,
            default() {
                return {};
            },
        },
        episodeSlugs: {
            type: Object,
            default() {
                return {};
            },
        }

    },
};
</script>

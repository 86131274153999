<template>
    <div v-if='model && !model.is_null'>
        <div class='text-right last_update'>Data ultima modifica: {{ lastUpdateDate }}</div>
        <form @submit='save' @change='formIsDirty'>
            <div class='grid p-fluid formgrid'>
                <div class='field col-12 md:col-6'>
                    <div class="p-inputgroup">
                    <span class='p-float-label'>
                        <InputText id='plain_slug'
                                   type='text'
                                   v-model="v$.model.slug.$model"
                                   :class="{'p-invalid': v$.model.slug.$errors.length}"
                        />
                        <Button label='Fix' class="p-button-warning" title='Correggi SLUG' @click='model.slug = sluggify(model.slug)'/>
                        <label for='plain_slug'>Slug</label>
                    </span>
                    </div>
                </div>
            </div>

            <div class='grid p-fluid formgrid mt-3'>
                <div class='field col-12  md:col-6'>
                    <uppy-dashboard
                        :uppy='uppyIcon'
                        :props='uppyDashboardProps'
                    />
                </div>
                <div class='field col-12  md:col-6'>
                    <span class='p-float-label'>
                        <InputText id='icon'
                                   type='text'
                                   v-model="model.icon"
                        />
                        <label for='icon'>Icona (mini)</label>
                    </span>
                    <div v-if='model.icon' class='text-center'>
                        <img class="mt-4" :src='model.icon' alt='Model Image' :height='uppyDashboardProps.height-50'/>
                    </div>
                </div>
            </div>

            <div class='grid p-justify-center mt-4'>
                <div class='col-12 text-center'>
                    <SaveButton @click='save' :load='loading'/>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers('Cont/B2BPaths');
import useVuelidate from '@vuelidate/core'

import model from './model';

import Navigations from '../../../mixins/navigations';
import Notifications from '../../../mixins/notifications';
import Uploader from '../../../mixins/uploader';

export default {
    mounted(){
        this.model = this.row;
        this.prepareUploads(model.uploadsPlain, this.lang);
    },
    setup () {
        return { v$: useVuelidate() }
    },
    validations () {
        return model.modelValidation;
    },
    computed: {
        ...mapGetters(['row', 'loading']),
    },
    watch: {
        row(n){
            this.model = n;
        }
    },
    beforeUnmount() {
        this.resetUploads(model.uploadsPlain);
    },
    methods: {
        ...mapActions(['savePlain','createPlain']),
        ...mapMutations(['formIsDirty', 'formIsClean']),
        navigateToNewItem(id, company_id){
            return this.$router.push({ path: this.$route['meta']['basePath'].replace(":company_id", company_id) + '/' + id });
        },
        async save () {
            const result = await this.v$.$validate()
            if (!result) {
                this.ShowError("Errore", "Alcuni campi del form non sono corretti");
                return
            }
            const save = (this.model.id > 0) ? this.savePlain : this.createPlain;
            const changeRoute = (this.model.id === 0);

            save(this.model).then( resp => {
                this.formIsClean();
                if (changeRoute){
                    this.navigateToNewItem(resp.id, resp.company_id).then( () => {
                        this.ShowSuccess('Ottimo', 'Percorso salvata con successo');
                    });
                    return;
                }
                this.ShowSuccess('Ottimo', 'Percorso salvata con successo');

            }).catch(err => {
                this.ShowStandardError(err.response);
            });
        },
    },
    components: {},
    mixins: [
        Navigations,
        Notifications,
        Uploader
    ]
};
</script>

import { createNamespacedHelpers } from 'vuex';
import tools from '../libs/tools';

const contStore = createNamespacedHelpers('Users');

export default {
    mounted(){
        this.getCompanies();
    },
    data(){
        return {
            dd_companies: [],
            dd_mapped_companies: {},
        };
    },
    computed: {
        ...contStore.mapGetters(['Companies/rows', 'Companies/loading']),
        companiesRefreshLoadingIcon(){
            return this['Companies/loading'] ? "pi pi-spin pi-spinner" : "pi pi-refresh";
        }
    },
    methods: {
        ...contStore.mapActions(['Companies/fetchList']),
        initCompanies(items){
            this.dd_companies = tools.models.listsToDropdown(items);
            if (items && items.length){
                this.dd_mapped_companies = {};
                items.forEach(row => {
                    this.dd_mapped_companies[this.toStrId(row['id'])] = row;
                });
            }
        },
        refreshCompanies(){
            this['Companies/fetchList']().then(items => {
                this.initCompanies(items);
            }).catch(err => {
                this.ShowStandardError(err.response);
            });
        },
        toStrId(id){
            return "c_"+id;
        },
        getCompany(id){
            if (this.dd_mapped_companies[this.toStrId(id)]) return this.dd_mapped_companies[this.toStrId(id)]
            return null;
        },
        getCompanies(){
            if (this['Companies/rows'] && this['Companies/rows'].length){
                this.initCompanies(this['Companies/rows']);
            } else {
                this.refreshCompanies();
            }
        }

    }
}
